<template>
    <div class="container H_100vh bg_fff">
        <div class="flex_row_between PLR_3 PTB_4">
            <van-icon name="arrow-left" @click="$router.go(-1)" size=".35rem" />
            <!-- <div class="flex_rowR">
                <van-icon name="add-o" class="color_F44C06" size=".35rem" />
                <span class="fontS_14 PL_2" @click="goTo">选择新增</span>
            </div> -->
        </div>
        <div class="PLR_5 textL PB_4 PT_2 fontS_17 font_weight_bold">
            请选择所要添加的技能类型
        </div>
        <div class="flex_rowC PLR_5 PB_4 relative">
            <input @input="text($event)" type="text" class="PTB_2 B_1_F44C06 PL_4 B_radius_5 flex_grow_1" placeholder="请输入内容">
            <van-icon name="search" size=".4rem" class="absolute color_F44C06" style="right: 10%" />
        </div>
        <div v-if="filterList.length == 0">
            <div class="D_flex color_686868">
                <van-sidebar v-model="activeKey">
                    <van-sidebar-item v-for="(item,index) in allList" :key="index" @click="getList(index,item.positionId,item.position_name,item.level)" :title="item.position_name" />
                </van-sidebar>
                <div class="rel flex_grow_1 W_60 flex_rows_start PT_1">
                    <div @click="getName(item.position_name,index,item.positionId,item.level)" :class="item.status?'txtColor':''" class="W_50 textC topstyle" v-for="(item,index) in list" :key="index">
                        {{item.position_name}}
                    </div>
                    
                </div> 
                <div @click="add" class="next">下一步</div>
            </div>
        </div>
        <div class="list" v-else>
          <div class="noactive" @click="searchMsg(item1,index)"  v-for="(item1,index) in filterList" :key="index">{{item1.position_name}}</div>
          <div class="addtel" @click="add">下一步</div>
        </div>

    </div>
</template>

<script>
    import '@/assets/css/common.css';
    // import { Toast } from "vant";
    export default {
        name: "",
        data() {
            return {
                activeKey:0,
                list:[],
                name:"",
                type:"",
                level:"",
                positionId:"",
                allList:[],
                filterList:[],
            }
        },
        mounted() {
        //    this.getList(213)
        
        this.getData()
           this.level = 1
        },
        methods: {
        searchMsg(item,index){
            console.log(index,item)
            this.positionId=  item.positionId
            this.name = item.position_name
            this.level = parseInt(item.level) +1
            //   let filterList = this.filterList
            
            //   for(var i=0;i<filterList.length;i++){
            //     if(index == i){
            //       filterList[index].status = true
            //     }else{
            //        filterList[i].status = false
            //     }
            //   }
    },
    text(e){
        let data = e.data
        let list = this.list
        let arr = []
        for(var i=0;i<list.length;i++){
            if(list[i].position_name.indexOf(data) >= 0) {
                arr.push(list[i])
            }
        }
        arr.map((val)=>{
        val.status = false
            return val
        })
        // console.log(arr)
        this.filterList = arr
        
    },
    getName(name,index,positionId,level){
        // console.log(name,index, positionId,  level)
        this.positionId = positionId
        this.level = level
        this.name = name
        let list = this.list
        for(var i=0;i<list.length;i++){
            if(index == i){
                list[index].status = true
            }else{
                list[i].status = false
            } 
        }
        // console.log(list)
    },
    getData(){
        this.$http.post("/user/v1/Position/positionList",{reqType:"positionList",pid:"",name:""})
        .then((res)=>{
            let res1 = JSON.parse(res.data)
            // console.log(res1)
            res1.data.forEach((value)=>{
                value.children.forEach((val)=>{
                    val.status = false
                })
            })
            this.allList = res1.data
            this.list = this.allList[0].children
        })
        .catch((e)=>{console.log(e)})
    },
    getList(index,positionId,position_name,level){
        console.log(positionId,position_name,level)
        this.name = ""
        this.positionId = positionId
        this.level = level
        this.type = position_name
        this.list = this.allList[index].children
    },
    add(){
        let type = this.type
        let name = this.name
        let positionId = this.positionId
        let level = this.level
        // console.log(`type:${type},name:${name},positionId:${positionId},level:${level}`)
     this.$router.push({path:'/addTag',query:{type:type,name:name,positionId:positionId,level:level}});
    },
    goTo() {
        this.$router.push('/addTag');
    }
},
    }
</script>

<style scoped>
.van-sidebar-item--select::before {
    background-color: #ffffff;
}
.container{
  position: fixed;
  height:100%;
  width:100%;
  background-color: #fff;
  top: 0;
  bottom:0;
  overflow-y:scroll;
  overflow-x:hidden;
  }
.topstyle{
    margin-top:0.35rem;
}
.rel{
    position: relative;
}
.next{
  position: absolute;
  bottom: 0.5rem;
  left:2.5rem;
  width: 4.01rem;
  height: 0.97rem;
  line-height: 0.97rem;
  background: linear-gradient(90deg, #FF4800 0%, #FC9E46 100%);
  border-radius: 0.16rem;
  text-align: center;

  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
}
.txtColor{
  color:#FF4800
}
.addtel{
  position:absolute;
  bottom:1rem;
  left:1.7rem;
  width: 4.01rem;
  height: 0.97rem;
  line-height: 0.97rem;
  background: linear-gradient(90deg, #FF4800 0%, #FC9E46 100%);
  border-radius: 0.16rem;
  text-align: center;

  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
}
.noactive{
    height: 0.7rem;
    line-height: 0.7rem;
    background: #FFFFFF;
    border-radius: 0.4rem;
    border: 0.01rem solid #979797;
    padding:0 0.5rem;
    font-size: 0.28rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #979797;
    margin-right:0.3rem;
    margin-bottom:0.2rem;
}
.list{
    display:flex;
    flex-wrap: wrap;
    margin-top:0.46rem;
    margin-left:0.25rem;
}

</style>